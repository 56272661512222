( function($) {

    // First selector is for regular form fields, second is for gravity form inputs, and the third is for gravity form text area
    //$( ".gfield.text-field:not(.gfield_error) > input, .gfield.text-field:not(.gfield_error) > div > input, .gfield.text-field:not(.gfield_error) > div > textarea, .gfield.select-field:not(.gfield_error) > div > select"
    $( ".gfield.text-field > input, .gfield.text-field > div > input, .gfield.text-field > div > textarea, .gfield.select-field > div > select"
        ).each( function() {
            if( $( this ).val() !== "" ) {
                $("label[for='" + $(this).attr("id") + "']").addClass( "hidden" );
            }
        }
        ).on(
        "focus", '', function() {
            $("label[for='" + $(this).attr("id") + "']").addClass( "hidden" );
        }
        ).on(
        "blur", '', function() {
            if( $( this ).val() === "" ) {
                $("label[for='" + $(this).attr("id") + "']").removeClass( "hidden" );
            }
        }
    );

    if( $( ".newsletter-form-section .newsletter-form-container .gform_confirmation_wrapper" ).length ) {
        // Confirmation section exists for the newsletter form, so hide the heading
        $( ".newsletter-form-section .newsletter-header" ).css("display","none");
    }

} )( jQuery );