/**
 * Function for loading query items via ajax calls
 */
(function ($) {
  function load_query_func(el, item) {
    // disable button for accidental double clicks
    el.prop("disabled", true);

    // Pass in any necessary attributes
    var button = el,
      data = {
        action: el.attr("action"),
        // posts_per_page: el.attr("posts-per-page"),
        category: el.attr("category"),
        post_type: el.attr("post-type"),
        paged: el.attr("paged"),
        site: "", // value required for multisites
      };

    $.ajax({
      url: data.site + "/wp-admin/admin-ajax.php", // AJAX handler
      data: data,
      type: "POST",

      beforeSend: function () {
        $shopPage = "shop-product";
        if (!el.attr("clicked") && !$shopPage) {
          button.text("Loading..."); // change the button text
        }
      },

      success: function (html) {
        el.prop("disabled", false); // re-enable the load more button
        el.attr("clicked", true);

        if (html) {
          // console.log( html );

          // Clear our the existing page of items
          item.empty();

          // Display the new page of items
          item.append(html);
        } else {
          button.hide(); // if no data
        }
      },
    });
  }

  // View Details button.
  $(".load-query-button").click(function (e) {
    e.preventDefault();

    load_query_func(
      $(this),
      $(this).closest(".shop-category").find(".brand-list-content")
    );

    $(this).removeClass("load-query-button");
    $(this).removeAttr("action");
  });

  // Toggling button label
  $(".load-query-toggle").click(function (e) {
    e.preventDefault();

    $(this).toggleClass("expand-query");

    if ($(this).hasClass("expand-query")) {
      $(this).html('View Less <i class="fa-light fa-angle-up"></i>');
    } else {
      $(this).html('View Products <i class="fa-light fa-angle-down"></i>');
    }
  });

  // Toggling button label for the logo and brand name in the product brand Card on the Shop page
  $(".load-query-toggle-for-card").click(function (e) {
    e.preventDefault();

    $(".load-query-toggle").toggleClass("expand-query");

    if ($(".load-query-toggle").hasClass("expand-query")) {
      $(".load-query-toggle").html(
        'View Less <i class="fa-light fa-angle-up"></i>'
      );
    } else {
      $(".load-query-toggle").html(
        'View Products <i class="fa-light fa-angle-down"></i>'
      );
    }
  });
  // -------------------------

  // Function for pagination nav
  $(document).on("click", ".pagination a.page-numbers", function (e) {
    e.preventDefault();

    /**
     * These pagination controls come without attributes.  We need to add them
     * so our ajax query can function properly
     **/
    var thishref = $(this).attr("href");
    var paged = thishref.replace(/^\D+/g, "");
    var shopCategory = $(this).closest(".shop-category").find("h3").text();

    $(this).attr("clicked", true);
    $(this).attr("action", "shopproductposts");
    $(this).attr("post-type", "shop-product");
    $(this).attr("category", shopCategory);
    $(this).attr("paged", paged);

    load_query_func($(this), $(this).closest(".brand-list-content"));
  });
})(jQuery);
