(function ($) {
  $(".mobile-navbar li.menu-item-has-children > a").one("click", function () {
    var target = $(this);
    var value = target.attr("href");
    var element = target.parent("li");

    target.removeAttr("href");

    element
      .children("ul")
      .first("li")
      .prepend('<li><a href="' + value + '">Overview</a></li>');
  });

  $("a.uk-navbar-toggle").on("click", function () {
    // Hide the partner button, show the site logo and switch the background color to default
    $(".header-items .partner-button").toggleClass("mobile-header-toggle");
    $(".header-items .logo-container").toggleClass("mobile-header-toggle");
    $("header.crossover-header-container").toggleClass(
      "default-background-color"
    );

    $(".crossover-header-container").toggleClass("mobile-dark-header");
  });

  $(".mobile-menu .button-container a.crossover-button").on("click", function () {
    // Allow the become a partner form to be accessible on mobile
    $(".crossover-header-container").removeClass("mobile-dark-header default-background-color");
    $(".mobile-items").toggleClass("mobile-header-toggle");
  });
})(jQuery);
